import React from 'react'
import TextField from '@mui/material/TextField';


function InputFieldComponent({label='textField',variant='outlined',type='text', required=false, value='value',placeholder='', onChangeFn}) {

    const onChangeAction = (e)=>{
            onChangeFn(e.target.value);
    }

  return (
    <TextField type={type} fullWidth  sx={{width:"100%"}} id="outlined-basic" label={label} variant={variant} required={required} value={value} onChange={onChangeAction} placeholder={placeholder} />
  )
}

export default InputFieldComponent