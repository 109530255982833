import React, { useState } from 'react'
import axios from 'axios';

import { Grid, Stack, Typography, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

import InputFieldComponent from '../../components/inputFields/InputFieldComponent';
import IconButton from '../../components/buttons/IconButton';
import Snackbar from '../../components/snackbar/Snackbar';
import Loader from '../../components/loader/Loader';
import DialogComponent from '../../components/dialogComponent/DialogComponent'


function Form() {

    const prod = 'https://api.ritzmotoclub.com/' // This is the new production domain  'https://rmc-api-phinahas-projects-41da129b.vercel.app/';
    const local = 'http://localhost:8080/';


    const [loading, setLoading] = useState(false);
    const [dialogState,setDialogState] = useState(false);
    const [dialogMessage,setDialogMessage] = useState('');
    const [dialogSubtitle,setDialogSubtitle] = useState('');

    const [snackbarState, setSnackbarState] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('Testing alerts');
    const [snackbarServity, setSnackbarServity] = useState('info');


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [variant, setVariant] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [whatsappNumberCountryCode, setWhatsappNumberCountryCode] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [address, setAddress] = useState('');
    const [postOffice, setPostOffice] = useState('');
    const [district, setDistrict] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');


    const changeSnackbarState = () => {
        setSnackbarState(false);
    };

    const changeDialogState = () => {
        setDialogState(false);
        window.location.reload();

    };





    const formSubmit = () => {
        try {

            console.log(name, email, address, phoneNumber, whatsappNumber, address, pincode);

            if (!name || name.length < 2 || !email || email.length < 3 || !phoneNumber || !whatsappNumber || whatsappNumberCountryCode.length < 2 ||
                !whatsappNumberCountryCode || phoneCountryCode.length < 2 || !phoneCountryCode || !address || address.length < 2 || postOffice.length < 2 || !postOffice ||
                district.length < 2 || !district || state.length < 2 || !state || pincode.length < 2 || !pincode
            ) {
                setSnackbarMessage("Some required data is missing");
                setSnackbarServity('error');
                setSnackbarState(true);

                return;
            }

            if (!Number(phoneNumber) || !Number(whatsappNumber) || !Number(whatsappNumberCountryCode) || !Number(phoneCountryCode) || !Number(pincode)) {
                setSnackbarMessage("Something wrong with the number fields, check phone number or pincode fields");
                setSnackbarServity('error');
                setSnackbarState(true);

                return;
            }

            // if (phoneNumber.length !== 10 || whatsappNumber.length !== 10) {
            //     setSnackbarMessage("check your given numbers length");
            //     setSnackbarServity('error');
            //     setSnackbarState(true);

            //     return;
            // }

            setLoading(true);
            axios.post(`${prod}v1/user/auth/form-signup`, { name: name, email: email, phoneNumber: phoneNumber, whatsappNumber: whatsappNumber, address: address, pincode: pincode, phoneCountryCode: phoneCountryCode, whatsappNumberCountryCode: whatsappNumberCountryCode, postOffice: postOffice, district: district, state: state, registrationNumber: registrationNumber, variant: variant }).then((response) => {

                setLoading(false);
                setDialogMessage("Thankyou !!");
                setDialogSubtitle("Request Sumbitted")
                setDialogState(true);
                // setSnackbarMessage(response.data.message);
                // setSnackbarServity('success');
                // setSnackbarState(true);

                return;


            }).catch((error) => {

                console.log(error);
                setLoading(false);
                setDialogMessage("Info");
                setDialogSubtitle(error.response.data.message)
                setDialogState(true);
                

                return;
            })





        } catch (error) {
            console.log(error)
            setLoading(false);
            setSnackbarMessage(error.message);
            setSnackbarServity('error');
            setSnackbarState(true);
            return;
        }
    }




    return (
        <>
            <Snackbar message={snackbarMessage} openStatus={snackbarState} severity={snackbarServity} onCloseFunction={changeSnackbarState} />
            <DialogComponent openState={dialogState} onChangeOpenState={changeDialogState} caption={dialogSubtitle} message={dialogMessage}  />
            <Loader openState={loading} />
            <Grid container>

                <Stack width={'100%'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mt={4}>
                    <Typography variant='h4' fontFamily={'Poppins !important'}>Registration Form</Typography>
                </Stack>

                <Grid container

                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: '100%' }}
                    mt={5}>



                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={11} sm={6} md={3} lg={5}   >
                            <InputFieldComponent label='Name' required={true} onChangeFn={setName} value={name} />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={11} sm={6} md={5} lg={5} mt={2} >
                            <InputFieldComponent label='Email' type='email' required={true} onChangeFn={setEmail} value={email} />
                        </Grid>

                    </Grid>

                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={11} sm={6} md={5} lg={3} mt={2}>
                            <InputFieldComponent label='Reg. No' required={true} onChangeFn={setRegistrationNumber} value={registrationNumber} placeholder='KL-00-XX-0000' />
                        </Grid>
                        <Grid item xs={11} sm={6} md={6} lg={2} mt={2} >
                            <Box sx={{ width: '100%', borderRadius: '12px' }}>
                                <FormControl fullWidth sx={{ backgroundColor: 'white', borderRadius: '12px' }}>
                                    <InputLabel id="demo-simple-select-label">Variant</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Variant"
                                        value={variant}
                                        onChange={(e) => { setVariant(e.target.value); }}

                                    >
                                        {['LXi', 'VXi', 'Zxi', 'LDi', 'VDi', 'ZDi'].map((variant) => {
                                            return <MenuItem key={variant} value={variant}>{variant}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>



                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={2} sm={2} md={1} lg={1} mt={2}>
                            <InputFieldComponent label='Code' placeholder='91' required={true} onChangeFn={setPhoneCountryCode} value={phoneCountryCode} />
                        </Grid>
                        <Grid item xs={9} sm={4} md={4} lg={4} mt={2} >
                            <InputFieldComponent label='Phone Number' required={true} onChangeFn={setPhoneNumber} value={phoneNumber} />
                        </Grid>
                    </Grid>



                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={2} sm={2} md={1} lg={1} mt={2}>
                            <InputFieldComponent label='Code' placeholder='91' required={true} onChangeFn={setWhatsappNumberCountryCode} value={whatsappNumberCountryCode} />
                        </Grid>
                        <Grid item xs={9} sm={4} md={4} lg={4} mt={2} >
                            <InputFieldComponent label='Whatsapp Number' required={true} onChangeFn={setWhatsappNumber} value={whatsappNumber} />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={11} sm={6} md={12} lg={5} mt={2}  >
                            <InputFieldComponent label='Address' required={true} onChangeFn={setAddress} value={address} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={11} sm={6} md={12} lg={5} mt={2}  >
                            <InputFieldComponent label='Post Office' required={true} onChangeFn={setPostOffice} value={postOffice} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={11} sm={6} md={12} lg={5} mt={2}  >
                            <InputFieldComponent label='District ' required={true} onChangeFn={setDistrict} value={district} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={11} sm={6} md={12} lg={5} mt={2}  >
                            <InputFieldComponent label='State ' required={true} onChangeFn={setState} value={state} />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                        <Grid item xs={11} sm={6} md={12} lg={5} mt={2} >
                            <InputFieldComponent label='Pincode' onChangeFn={setPincode} value={pincode} required={true} />
                        </Grid>
                    </Grid>








                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Grid item xs={5} sm={6} md={5} lg={5} mt={2} >
                            <IconButton label='Submit' startIcon={<DoneIcon sx={{ fontSize: '1.7em !important', color: 'white !important', marginBottom: '0.2em !important' }} />} onClick={formSubmit} />
                        </Grid>
                    </Grid>

                </Grid>


            </Grid>

        </>

    )
}

export default Form