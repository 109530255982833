import Form from './pages/Form/Form'

function App() {
  return (

    <Form />

  );
}

export default App;
