import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

export default function FormDialog({ openState,message='',caption='', onChangeOpenState }) {








    return (
        <div>
            <Dialog open={openState} onClose={onChangeOpenState} sx={{padding:'250'}}>
                {/* <DialogTitle>{"title"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText>
                       <Typography variant='h4' children={message} />
                       <Typography variant='caption' children={caption} />
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button size='small' onClick={onChangeOpenState} sx={{
                        color: 'white', backgroundColor: "#FFA559", '&:hover': {
                            backgroundColor: 'orange',
                            color: 'white',
                        },
                    }}
                    >Ok
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}